.gl-header {
  min-height: 70px !important;
  .navbar-header {
    .navbar-brand {
      position: absolute;
      img {
        display: inline-block;
        height: 32px;
        margin-right: 10px;
      }
    }
  }

  .navbar-nav {
    @media (min-width: 768px) {
      padding-top: 8px;
      li {
        margin: 0;
      }
    }
  }
}
