// Header text feature
.call-out {
  @extend %padding-regular;
  display: inline-block;
  width: 100%;
  background-color:$header-desc-background-color;
  background-size: cover;
  font-size: 1.2em;
  text-align: center;
  color: $header-desc-text-color;
}
.call-out p:last-child {
  margin-bottom: 0;
}
// Post listing
.posts {
  .post {
    margin: 4rem auto;
    padding-bottom: 4rem;
    border-bottom: #EBF2F6 1px solid;

    .post-link:hover {
      text-decoration: none;
    }
  }

  .post-teaser {
    @extend %padding-regular;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
    background-size: $feature-image-size;
    border-bottom: 1px solid $border-color;
    p a:hover {
      @extend %link-hover;
    }
  }
  .excerpt {
    margin-top: 1em;

    // Continue reading
    .button {
      border: none;
      padding: 0;
      margin: 0;
      font-weight: 500;
    }

    a.button:hover {
      background: none;
      color: $link-color;
      opacity: 0.7;
    }
  }
}
// Pagination
.pagination .button {
  margin: 0 1.5em;
  i {
    vertical-align: middle;
  }
}
.pagination {
  padding: $padding-small $padding-large 0 $padding-large;
  text-align: center;
}
