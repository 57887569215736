// Mix-ins
%padding-small {
  padding: $padding-x-small $padding-x-small * 2;
  @media (max-width: 1000px) {
    padding: $padding-x-small $padding-x-small;
  }
}
%padding-regular {
  padding: $padding-small $padding-large;
  @media (max-width: 1000px) {
    padding: $padding-small * 1.5 $padding-large / 1.6;
  }
}
%link-hover {
  text-decoration: underline;
  color: darken($link-color, 15%);
}
// Buttons
.button {
  border-radius: 0.3em;
  border: 1px solid;
  display: inline-block;
  margin: 1em 0;
  padding: 0.5em 0.75em;
}
a.button:hover {
  background: $link-color;
  border: 1px solid $link-color;
  color: $background-color;
  text-decoration: none;
}
// States
.disabled {
  opacity: 0.7;
}

.content-container {
  max-width: 740px;
}

.btn-hero {
  background-color: #FFCD19;
  font-size: 16px;
  color: #333333;
  padding: 10px 20px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}

.languages {
  list-style: none;
  margin: 0;
  padding: 0;

  &:after{
    content: ".";
    display: block;
    height: 0;
    font-size: 0;
    clear: both;
    visibility:hidden;
  }

  li {
    float: left;
    a {
      padding-top: 2px;
      display: block;
      background-color: #bbb;
      text-decoration: none;
      text-transform: uppercase;
      color: white;
      width: 34px;
      height: 34px;
      text-align: center;
      -moz-border-radius: 16px;
      -webkit-border-radius: 16px;
      border-radius: 20px;

      &:hover {
        background-color: #ccc;
      }
    }
  }
}
